import React from 'react';
import {Link} from "gatsby";
import {getImage} from "../../contentful/data-provider";
import {renderRichText} from "gatsby-source-contentful/rich-text";

function BlogPostsSmallSection({content}) {
    return (
        <section className={'blog'}>
            <div className="container">
                <div className="row blogpost--container__list pt-4 mt-4">
                    <div className="col-lg-12 mb-5">
                        <h2>{content.title}</h2>
                    </div>
                    <div className="col-lg-12 mb-5">
                        <div className="row blog--container__list center">
                            { content.elements.map((post, index) => {
                                if (!post.category) {
                                    return;
                                }
                                return (
                                    <div className="col-md-4 mb-lg-80" key={index}>
                                        <div className="blogbox">
                                            <Link to={`/blog/${post.category.slug}/${post.slug}`}>
                                                <div className="blogbox--header">
                                                    <div className="tag-container">
                                                        { post.tags && post.tags.split(',').map((tag, index) => {
                                                            return (
                                                                <span className="tag" key={index}>{tag.title}</span>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="img-container">
                                                        <img src={getImage(post.image).url}
                                                             className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                             alt={getImage(post.image).title}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="blogbox--content">
                                                    <span className="date">{post.publishedAt}</span>
                                                    <h3>{post.title}</h3>

                                                    <div className="blogbox--content__inner">
                                                        {post.excerpt && renderRichText(post.excerpt)}
                                                    </div>
                                                    <div className={`btn-container ${post.ctaButton ?? 'd-none'}`}>
                                                        <div className={`more`}>
                                                            <i className="fas fa-arrow-right"/> Read Article
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            }) }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BlogPostsSmallSection;
